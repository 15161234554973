import Core from './core.js';
import Myself from './myself.js';
import Subscribe from './subscribe.js';
import "./head.js";
import Table from "./mods/table.js";

// /xhr/html?req=block&block=tours&hall=1&started=0 - tours timetable
// /xhr/html?req=block&block=tours&hall=1&started=1 - completed tours

async function awaitVgame() {
	await import( './mods/vgame.js' );
}

export default function( swiper ) {
	let nologin = false,
		atop,
		waitForItem = null,
		activeGame,
		// Title,
		requestnewtableID = null,
		blurTime,
		floatMyGame = false,
		lastActionGame,
		countMovesShow,
		myregs = new Map,
		td = new Map,
		// var waitingItems = [];
		self = {},

		mainArea = construct( '.mainarea' ),
		lostInfo = construct( '.noconnectionpanel.track_disconnected {Connection}...' ),
		moveNotifier = construct( '.fade.movenotifier.flexline.center {Jumptonextmove}', mainArea, jumpNextAction ),
		// homeArea = createappend( 'fade homearea', mainArea ),
		myGames = construct( '#mygamespanel.invitations', mainArea ),
		shortCuts = construct( '.shortcuts', mainArea ),
		room, head;

	checkModules();

	Core.panel_fastReg = construct( '.fade.fastregpanel', mainArea );

	// myGames.appendChild( createcontent( null, '{Mygames}' ));

	self.myGames = myGames;
	self.findGame = findGame;
	self.mainArea = mainArea;
	self.bobUp = bobUp;

	mainArea.onSwiperChange = val => room?.onShow( val );
	mainArea.onSwiperCheckActive = () => room?.checkActive();
	mainArea.swipeID = 'room';

	async function makeFriends() {
		if( self.friends ) return;
		let mod = await import( './mods/usersview.js' );
		self.friends ||= new mod.Usersview( {
			parent: window.invisibleBody(),
			contacts: true,
			onclick: 'showchatnotempty'
		} );
		self.friends.mainHolder.classList.add( 'friends', 'display_none' );
	}

	function checkModules() {
		makeFriends();

		if( !room && window.modules.Room ) {
			room = window.modules.Room( mainArea, self );
			room.onShow( mainArea.dataset['swipetop'] );		// Виден manager или нет из параметра swipetop
		}

		if( !head && window.modules.Head ) {
			head = window.modules.Head;
			head.holder.appendChild( Myself.holder );
			if( !(coreParams.noplaybutton && !LOCALTEST) ) {
				let playButtonHolder = construct( '#playbutton.display_none.importantsize', head.holder ),
					play = construct( '.mybutton {Play}', playButtonHolder,
						playSelect );
			}
			head.setParent( mainArea );
		}
	}

	async function goLocation( location ) {
		log( 'Manager golocation ' + location );
		if( window.EXTERNALDOMAIN && !window.domainData ) {
			log( 'Waiting for domain data for ' + window.location.hostname );
			return;
		}

		location ||= window.domainData?.root ?? window.coreParams?.default ?? 'home';
		location = location.toString();
		let mix = location?.split( ':' );
		if( mix ) location = mix[0];
		if( Core.location===location ) return;
		if( Core.location===Core.aliases.get( location ) ) return;
		if( window.NOSOCKET ) return;

		Swiper.beforeRelocate();
		Swiper.current?.onRelocate?.();

		log( 'Manager golocation 1 ' + location );
		if( location ) {
			location = location.toString();
			if( location[0]==='/' ) location = location.slice( 1 );
		}
		// location ||= 'home';
		Core.setAutoJump();			// Clear auto jumps
		if( location==='home' ) location = window.domainData?.root ?? window.coreParams?.tableviewstart ?? 'room_9';
		let uniq = checkUniq( location );
		if( uniq?.[0].toLowerCase()==='g' || location.startsWith( 'game_' ) || location.startsWith( 'game.' ) || location.startsWith( 'g-' ) ) {
			return goGame( location );
		}
		fire( 'goroom', location );
	}

	function goGame( location ) {
		log( 'Manager goGame ' + location );
		let item;
		if( location ) {
			item = (location.startsWith( 'game.' ) || location.startsWith( 'tv-' )) && location.replace( '.', '_' )
				|| (location.startsWith( 'game_' ) && location);
			if( !item ) {
				let uniq = checkUniq( location );
				if( uniq ) item = 'game_' + uniq;
			}
		}
		if( !item || item==='room' ) return false;
		// Был показан стол или запрошен стол, попробуем его переоткрыть
		subscribeGame( item, null, true );
		return true;
	}

	window.goLocation = loc => goLocation( loc );

	dispatch( 'golocation', location => {
		goLocation( location );
	} );

	dispatch( 'newmodule', checkModules );

	Subscribe.add( '_shortcut', ( data, minor ) => {
		shortCuts.appendChild( data.shortCut );
	} );

	if( !window.EXTERNALDOMAIN || window.domainData )
		startLocate();
	else
		dispatch( 'domaindataready', startLocate );

	function startLocate() {
		let item = Core.itemCode,
			openWithGame = goGame( item || sessionStorage.view );
		if( !openWithGame && item[0]==='d' && item.length===33 ) {
			// d<32sym> is not always a uniq key
			import( './mods/intent.js' ).then( mod => mod.intent( item ) );
			return;
		}
		if( !openWithGame ) {
			fire( 'setroomnow', item );
			goLocation( item );
		}
		Core.itemCode = null;
		// if( !openWithGame )
		// 	bobUp( null, "ready-viewroom" );

		// Зачем мы активируем зал, если всё равно будет открыта игра?
		// Swiper.addPage( mainArea, false ); // Always activate !!openWithGame ); // , true );
		Swiper.addPage( mainArea, !!openWithGame );
	}

	dispatch( 'disconnected', () => {
		// Ставим признак устаревших игровым столам
		// for ( let [k,g] of gameByItem ) g.obsolete = true;

		// Для всех объектов со стилем .dependsconnection
		// Устанавливаем аттрибут connection=lost
		mainArea.classList.toggle( 'disconnected', true );
		// var a = document.getElementsByClassName( 'dependsonconnect' );
		// for ( var i = a.length; i--; ) a[i].removeAttribute( 'connected' );

		needcheckbuttons();
	} );

	dispatch( 'connected', () => {
		mainArea.classList.toggle( 'disconnected', false );
		floatMyGame = false;					// Первый раз после получения связи не показываем игровые окна
		needcheckbuttons();
	} );

	function needcheckbuttons() {
		delay( 'checkbuttons' );
	}

	Subscribe.add( '_me', async ( o, minor ) => {
		switch( minor ) {
			case 'friendsonline':
				// Create and show friends window
				// Should be cleared on relogin/logout
				await makeFriends();
				let old = self.friends.count;
				self.friends.mset( o );
				if( !old && self.friends.count ) fire( 'friendsblinked' );
				break;

			case 'plays':
				// for( let g of myGames ) g.obsolete = true;
				let float = floatMyGame;
				floatMyGame = true;
				if( !o ) {
					if( window.powermanagement ) {
						log( 'Release power on' );
						powermanagement.release();
					}
					return;
				}
				if( window.powermanagement ) {
					log( 'Trying to keep device on-power' );
					powermanagement.acquire();
				}
				await awaitVgame();
				for( let k in o ) {
					let vg = elephVgame.find( k );
					if( vg ) continue;
					let chain = o[k]['game'].split( '.' );
					let type = chain[0];
					if( type==='chio' ) type = 'chipoker';
					if( chain[1]==='POKER' ) type = 'poker';
					subscribeGame( k, type, floatMyGame );
				}
				fire( 'hideallwindows' );
				break;
			case 'invites':
				import( './mods/invitation.js' );
				break;

			case 'td':
				// Какими турнирами управляет
				if( td.parseMSET( o ) ) {
					let mod = await import( './mods/touricon.js' );
					td.tourIcons ||= new Map;
					mod.Touricon.checkIcons( td.keys(), td.tourIcons, myGames );
				}
				break;
		}
	} );

	dispatch( 'actiondrops', () => {
		// if( atop && atop.waitAction ) return;
		// let newgame = jumpNextAction();
	} );

	dispatch( 'wantsaction', game => {
		lastActionGame = game;
		if( game.holder!==Swiper.current ) {
			// TODO: здесь должна присутствовать проверка
			// на то, что сейчас открыта игра, которая ждет хода
			return fire( 'swipeto', game.holder );
		}
		checkMyMoves();
		/*				delay( () => {
							checkNewMove();
						} ); */
	} );

	dispatch( 'afterfromserver', function() {
		// Проверим, не все ли игры у нас устарели
		checkobsoletegames();

		// checkNewMove();
		checkMyMoves();

		// if ( !atop && !waitForItem ) bobUp( null, 'afterMessage' );
	} );

	dispatch( 'onpause', function() {
		blurTime = Date.now();
		log( 'Paused' );
	} );

	dispatch( 'onresume', () => {
		blurTime = 0;
		log( 'Resuming timers' );
	} );

	function hashCode( str ) {
		let hash = 0;
		for( let i = str.length; i--; ) {
			hash = ((hash << 5) - hash) + str.charCodeAt( i );
			hash = hash & hash; // Convert to 32bit integer
		}
		return hash;
	}

/*
	dispatch( 'settitle', function( ttl ) {
		Title = ttl?.title || ttl;
//		buttonGame.lastChild.textContent = ttl
	} );
*/

	function bobUp( p, reason ) {
		log( 'bobUP ' + reason );
		if( !p || p==='room' ) p = null;

		Swiper.act( p && p.holder || mainArea );

		needcheckbuttons();
		checkMyMoves();
	}

	function findGame( item, obsolete ) {
		let g = window.elephVgame?.findGame( item );
		if( !g ) return null;
		if( g.obsolete && !obsolete ) return null;
		return g;
	}

	function checkobsoletegames() {
		checkActiveGame();
	}

	function checkActiveGame( g ) {
		if( !atop || !atop.item || !atop.obsolete ) return;

		if( g && !g.obsolete ) {
			activeGame = g;
			return;
		}

		activeGame = atop;
		if( activeGame.obsolete ) activeGame = null;

		// Проверим показываемый объект
		if( !waitForItem ) {
			// Если не зал, то должна быть активная игра
			if( atop!==activeGame ) bobUp( activeGame, "CheckActiveGame" );
		}
	}

	dispatch( 'userasktable', async function( table ) {
		// Если этот стол уже открыт, просто покажем его
		let item = table.gameItem;
		if( !item ) return;
		let vg = window.elephVgame?.find( item );
		if( vg?.game ) {
			// if( game.LOADING ) return log( 'Game ' + id + ' is still loading (show progress)' );
			bobUp( vg.game, 'userasktable' );
		} else {
			// Подпишемся на игру, и сразу покажем
			if( vg ) {
				return log( 'Waiting for module for game...' );
			}
			if( !table.module ) return;
			// Попробуем сделать запрос на подход к столу через сервер
			if( table.isbridge /*&& table.isFreeKibi*/ ) await Core.checkAuth( 'complete' );
			if( table.askCome ) {
				let j = await Core.do( 'type=askcome item=' + item );
				if( j ) {
					if( !j.allow && !LOCALTEST ) return;
				}
			}
			subscribeGame( item, table.module, true );

			// Закроем неигровой стол, если такой есть
			let pages = Swiper.allPages();
			for( let i = pages.length; i--; ) {
				let page = pages[i];
				if( page.classGame && !page.classGame.isPlayer ) {
					Swiper.removePage( page );
					break;
				}
			}
		}
	} );

	function addBigGame( g ) {
		// Подключился игровой стол, показываем и добавляем в список игр
		checkActiveGame( g );
		checkMyMoves();
	}

	function checkMyMoves() {
		// Подсчитаем количество ходов, которые надо сделать, покажем их
		// учитывая игру, которая уже на экране
		if( !window.elephVgame ) return;
		let count = 0, countshow = 0;
		for( let vg of window.elephVgame.all() ) {
			if( !vg.game || !vg.game.waitAction ) continue;
			count++;
			if( vg.game.holder!==Swiper.current ) countshow++;
		}
		if( countMovesShow===countshow ) return;
		countMovesShow = countshow;
		moveNotifier.textContent = countshow;
		moveNotifier.makeVisible( countshow>0 );
	}

	function jumpNextAction() {
		if( !window.elephVgame ) return;
		let next, vg, first;
		for( vg of elephVgame.all() ) {
			let g = vg.game;
			if( !g ) continue;
			let wants = vg?.game?.wantsActions;
			if( wants && !first ) first = vg;
			if( !next && !g.maximized ) continue;
			if( !g.maximized && wants ) break;
			next = true;
		}
		if( !vg ) vg = first;

		if( vg ) bobUp( vg.game, 'jumpNextAction' );
	}

	/*
				function checkNewMove( lastactioned ) {
					let game = lastActionGame;
					lastActionGame = null;
					if( atop && atop.waitAction ) return;		// На экране игра с ожиданием хода
					if( game ) {
						bobUp( game, "float on move" );
					}
				}
	*/

	async function subscribeGame( item, modulename, float ) {
		let vg = window.elephVgame?.find( item );
		if( vg?.game ) {
			// if( game.LOADING ) return log( 'Game ' + id + ' is still loading (show progress)' );
			bobUp( vg.game, 'subscribeGame' );
			return;
		}

		if( !modulename ) modulename = 'game';
		log( 'Subscribe ' + item + ' (' + modulename + ') ' + (float ? 'with float' : '') );

		let diez = item.indexOf( '#' );
		if( diez>=0 ) item = item.slice( 0, diez );
		await awaitVgame();
		let vgame = await window.elephVgame.set( item, modulename );
		if( !vgame ) return;
		// When module is ready
		log( 'Subscribe completed: ' + item + ' (' + modulename + ') ' + (float ? 'with float' : '') );
		if( float ) {
			log( 'Float subscribed game' );
			if( vgame.game ) {
				Swiper.act( vgame.game.holder );
				closeBigWindows();
			}
		}
		addBigGame( vgame );
	}

	dispatch( 'loggedout', () => {
		self.friends?.clear();
		moveNotifier.hide();
		myGames.html( '' );
		fire( 'checkmygame' );
		fire( 'friendsblinked' );
	} );

	dispatch( 'checkmygame', game => {
		let holder = game?.picHolder;
		if( holder ) {
			let mine = game.isPlayer;
			if( mine ) {
				myGames.appendChild( holder );
			} else {
				if( holder.parentElement===myGames )
					myGames.removeChild( holder );
			}
		}
		myGames.makeVisible( myGames.children.length>0 );
		if( myGames.length )
			mainArea.setAttribute( 'mygames', myGames.children.length );
		else
			mainArea.removeAttribute( 'mygames' );
	} );

	// dispatch( 'activatetab', game => bobUp( game, 'activatetab' ) );
	// dispatch( 'hidetab', game => bobUp( null, 'hidetab.Lobbyclick?' ) );

	let mytables = new Map;

	Subscribe.add( '_me', ( o, minor ) => {
		if( minor==='plays' ) {
			if( o ) for( let k in o ) {
				let mt = mytables.get( k );
				if( mt ) continue;
				log( 'Mytable ' + k );
				if( o[k]['withrobots'] ) continue;
				// let type = o[k]['game'].split( '.' )[0];
				mt = new Table();
				mytables.set( k, mt );
				mt.module = o[k].module || 'game';
				mt.holder.classList.add( 'mytable' );
				mt.holder.dataset.game = k;
				mt.setItem( k, true );
				mt.holder.dataset.room = o[k].room;
				myGames.appendChild( mt.holder );
			}
			for( let [k,v] of mytables ) {
				if( !o[k] ) {
					try {
						v?.release();
					} catch( e ) {
						log( `Table [${k}/${mytables.size}]: id=${v?.id}` );
						bugReport( 'tablerelease: ' + k, e );
					}
					mytables.delete( k );
				}
			}
		}
	} );

	return self;
};

async function playSelect( e ) {
	e.target.$( 'button' )?.setSpinner( true );
	let module = await import( './mods/reger.js' );
	e.target.$( 'button' )?.setSpinner( false );
	module.playHall( e.target.dataset.hall );
	e.target.dataset.hall = '';
}
import './subscribe.js';

let bar = html( `<div class='myself_panel'>
				<button class='unlogged display_none' data-execute='auth.signin'>{Signin}</button>
				<span class="badge chatbadge allunreadcount hidenobadge display_none grayhover invertdark w48" data-execute="usersview.show" 
					style='order: 10; background: url( ${IMGEMBEDPATH}/svg/icons/ic_chat_black_24px.svg ) center no-repeat' data-badge="">					
				</span>				
				<div class='logged display_none'>
					<div class='avatarfants column center display_none' style='position: relative'>
					<img width='48' height='48' data-magictype='avatar' alt='' class='display_none control icon my_avatar grayhover nozoom'>
					<img width='24' height='24' alt='' src='${IMGEMBEDPATH}/svg/icons/ruby.svg' class='display_none premial'>
					<div class='my_panel'>
						<div class='hideempty nick hideinportrait'></div>
						<div class='display_none fants' title="{Fants}"></div>
					</div>
					</div>
				</div>
				<div class='clubmine flexline display_none'></div>
				<div class='tourmine flexline nowrap display_none'></div>
				</div>` );

let myAvatar = bar.$( '.my_avatar' ),
	myFants = bar.$( '.fants' ),
	myNick = bar.$( '.nick' );

	// if( FANTGAMES ) myFants.show();
	myFants.onclick = () => {
		if( GAMBLERRU && !LOCALTEST )
			return shopping( {
				ids: 'fants',
				reason: 'own'
			} );
		// Покажем новое окно информации об игроке
		import( './mods/team.js' ).then( mod => {
			mod.teamMember();
		})
	};

if( !elephCore.params.social ) myAvatar.show();

myAvatar.onclick = async e => {
	e.stopPropagation();
	if( elephCore.params.social ) return;		// No login or logout (fb/vk/...)
	if( !elephCore.isConnected ) return;
	log( 'Avatar click' );
	let mod = await import( './auth.js' );
	mod.clickMyself();
};

dispatch( 'loggedin', checkMe );
dispatch( 'checkmyself', checkMe );
checkMe();

function checkMe() {
	let me = elephCore.auth;
	if( me?.uid && !me.guest ) {
		// User.set( me.uid );
		log( 'Checkme ' + JSON.stringify( me ) );
		myAvatar.setMagic( me.info.picture || me.info.avatarid || me.uid, 'user_' + me.uid );
		// myAvatar.style.backgroundImage = User.getAvatarBackgroundImage( me.uid );
		myFants.textContent = prettyFants( window.MYFANTS?.value );
	} else {
		myAvatar.style.backgroundImage = null;
		myFants.setContent( '' );
		// myFants.setContent( '0' );
		// myAvatar.dataset['payload'] = '';
		myAvatar.setMagic( null, null );
		myAvatar.src = `${IMGPATH}/svg/icons/ic_person_outline_black_18px.svg`;
	}

	myAvatar.nextElementSibling.makeVisible( window.GAMBLERRU && me.info?.premium || false );

	myAvatar.dataset.name = me.uid ? 'user_' + me.uid + '.showname' : '';
	if( me.uid ) {
		// if( DEBUG ) {
		let user = User.get( me.uid );
		// log( 'Avatar waits update for ' + me.uid );
		let nick = me.info.showname || User.getNick( me.uid ) || /*me.id ||*/ localize( '{Guest}' );
		if( nick[0]==='@' ) nick = '';
		myAvatar.dataset['payload'] = nick + ' ( ' + me.uid + ' )';
		myNick.textContent = nick.includes( '@' )? nick.slice( 0, nick.indexOf( '@' )+1 ) : nick;
		// } else {
		// 	myAvatar.title = me.info && me.info.showname || 'No nick';
		// }
	} else {
		myNick.setContent( '' );
	}

	// В соцсети спрячем аватар
	if( me.extid )
		myNick.setContent( '' );

	// Если привязка соцсети к нику eleph, надо показать аватар
	if( me.extid && +me.uid ) {
		// let avs = document.querySelectorAll( '.my_avatar' );
		// for( let o of avs ) o.classList.add( 'visible' );
		myAvatar.show();
	}

	// Надо создать пользователя
	if( me.uid && me.info.showname?.[0]==='@' ) {
		log( 'Setting guest user ' + me.uid + ': ' + me.info.showname );
		let mu = User.set( me.uid );
		mu.cancelLoading();
		mu.setShowName( me.info.showname );
		mu.setPicture( me.info.picture || me.info.avatarid || me.info.showname.slice( 1 ) );
	}

	bar.$( '.unlogged').makeVisible( window.isGuest() );
	bar.$( '.logged' ).makeVisible( !window.isGuest() );
}

elephSubscribe.add( '_me', ( str, minor ) => {
	switch( minor ) {
		case 'pays':
			myAvatar.nextElementSibling.makeVisible( str?.premium && str.premium+TIMESERVERDIFF>=Date.now() || false );
			break;
		case 'fants':
			let value = prettyFants(typeof str==='object'? str.value : str );
			myFants.setContent( value || '0' ); // (window.IOS ? '' : '{Buy}') );
			if( value ) {
				log( 'Show fants value=' + value );
			}
			// if( value && !NEOBRIDGE && !FANTGAMES ) myFants.show();
			break;
		case 'tester':
			window.TESTER = +str;
			break;
	}
	// myCash.innerHTML = str && 'Ⓕ'+str || '';
} );

export default {
	holder: bar
};


import './support.js';

let Auth = {
		failedLoginTypes: new Set
	},
	waitingFetch = false,
	stored = {},
	longTries = 3,
	boxMyPassword, boxMyNick, boxMyShowname,
	// Сохранение всех авторизаций
	allSigned = JSON.parse( localStorage.allsigned || null ) || {};

export default Auth;
window.modules.Auth = Auth;
let subscribed = false;

function subscribeMe() {
	if( !window.elephSubscribe ) return;
	if( subscribed ) return;
	subscribed = true;
/*
	if( !modules.Subscribe ) {
		log( 'Module subscribe not ready' );
		return;
	}

*/
	elephSubscribe.addParser( 'user', myinfo );

	// TODO: сохранение последнего UIN в sessionStorage (считаем, что залогинен)
	elephSubscribe.addParser( 'AUTH', async ( o /*, minor*/ ) => {
		if( o.uid===UIN && elephCore.auth.server ) return;		// Уже залогинены
		let Subscribe = modules.Subscribe;
		Subscribe.drop( '_me' );
		delay( checkStartAction );
		// if( DEBUG ) {
		// 	o.error = 'Token illusion error';
		// 	log( 'Emulate token error' );
		// }
		if( o.error ) {
			// Clear stored session
			// localStorage['auth'] = null;
			log( 'Failed token authorization' );
			if( elephCore?.auth.alttoken ) {
				log( 'Trying alttoken ' + elephCore.auth.alttoken );
				window.setLoginStr( 'LOGIN -tag=token uuid=' + window.UUID + ' token=' + elephCore.auth.alttoken );
				elephCore.auth.alttoken = null;
				elephCore.auth.alttokenused = true;
				return;
			}
			window.socketLoginStr = null;
			Auth.failedLoginTypes.add( o.type );
			if( elephCore ) elephCore.auth.server = false;		// Пока только это (сброс подтверждения сервера)
			if( elephCore?.auth.long_token ) {
				if( !longTries ) {
					// Failed authorization
					log( 'Dont try long_token' );
					elephCore.auth = {};
					updateAuth();
					return;
				}
				longTries--;
				log( 'Trying long_token. Left tries: ' + longTries );
				Auth.fetch( {
					id: elephCore.auth.uid,
					long_token: elephCore.auth.long_token
				} );
				return;
			}
			clearAuth( 'AUTH error' );
			if( o.tag==='storedtoken' ) {
				if( elephCore.auth.type==='uuid' ) {
					checkGuestAutoLogin();
					return;
				}
			}
			Subscribe.set( '_me.auth', elephCore.auth );
			// Why we force autologin after failed token?..
			// Skip it for neobridge 04/08/23
			if( !NEOBRIDGE ) {
				if( elephCore.login )
					elephCore.login();
				else
					delay( 'checklogin' );
			}
			return;
		}
		// Notice alternate tocken
		// if( elephCore.auth.alttokenused )
		// 	callBugReport( 'Alternate tocken succeeded' );

		// Check if same login
		let uid = o['uid'] || o['uuid'] || null;
		if( uid && elephCore.auth.uid && uid!==elephCore.auth.uid ) {
			log( 'Wrong UID (' + uid + ') in AUTH. Old: ' + elephCore.auth.uid );
			delay( 'checklogin' );
			return;
		}
		if( o.token && o.token!==elephCore.auth.token ) {
			// Не тот токен, авторизация ошибочна
			log( 'Wrong token received' );
			delay( 'checklogin' );
			return;
		}
		elephCore.auth.server = true;
		elephCore.auth.uid = uid;
		// o.uin = o['uin'] || o['UIN'];
		let str = 'Logged as ' + uid;
		window.MYNAME = uid;
		if( 'info' in elephCore.auth ) {
			window.MYNAME = elephCore.auth.info.showname;
			if( MYNAME ) str += ': ' + MYNAME;
		}
		window.UIN = uid;
		window.myself = {
			_reason: 'AUTH',
			uin: uid
		};
		if( (+uid)<0 ) sessionStorage.GUESTUIN = window.GUESTUIN = UIN;
		// if( LOCAL ) Core.auth.info.showname = '';
		window.authPLR = User.set( uid, {
			name: elephCore.auth.info?.showname
		} );
		if( elephCore.auth.id ) window.authPLR.emailid = elephCore.auth.id;
		log( str );
		// Core.auth = o;
		// Core.auth.guest = !uid;
		// if( !o.showname && stored[o['uid']] ) o.showname = stored[o['uid']].showname;
		if( !elephCore.auth.guest && !(+UIN<0) )
			document.body.classList.add( 'loggedin' );

		delay( 'loggedin' );
		Subscribe.set( '_me.auth', elephCore.auth );
		// localStorage[ 'auth' ] = JSON.stringify( o );
		// В случае перелогинивания
		if( o.token )
			window.socketLoginStr = 'LOGIN -tag=authed uuid=' + window.UUID + ' token=' + o.token;

		if( !('info' in elephCore.auth) )
			elephCore.auth.info = {};
		if( elephCore.auth.info.fants )
			Subscribe.set( '_me.fants', elephCore.auth.info.fants );

		elephCore.setStorage( 'lastsuccesstoken', o.token );

		if( !sessionStorage['startplacedone'] ) {
			(async function() {
				sessionStorage['startplacedone'] = 1;
				let res = await elephCore.do( 'type=getstartplace' );
				if( res?.go && !elephCore.myGames.size )
					fire( 'goroom', res.go );
			})();
		}

		// Не нужно ли указать ник и пароль?
		checkNeedSet();

		waitingAuthResolve?.();
		waitingAuthResolve = null;

	} );
}

function myinfo( o, minor, secret ) {
	if( !elephCore ) {
		log( 'Core not ready' );
		return;
	}
	if( !secret || !elephCore?.auth || secret!==elephCore?.auth.uid ) {
		log( 'Not me: ' + secret + ', Iam ' + elephCore?.auth.uid );
		return;
	}
	if( !minor ) return;

	if( minor==='forcepetition' )
		return bugReport( o );

	if( minor==='logout' ) {
		// I am here, this is strange
		elephCore?.do( 'type=iamhere' );
		return; //  bugReport( o );
	}

	// log( 'user: major=' + major + "; minor=" + minor );
	elephCore.auth.info[minor] = o;

	modules.Subscribe?.set( '_me.' + minor, o );

	delay( updateAuth );
}

dispatch( 'saveauthinfo', updateAuth );

function updateAuth() {
	if( elephCore.auth )
		localStorage.lastsuccessauth = JSON.stringify( elephCore?.auth );
}

dispatch( 'trysocketlogin', e => {
	Auth.failedLoginTypes.clear();
} );

function clearAuth( reason ) {
	// localStorage['auth'] = null;
	// Core.auth.server = false;
	// Core.auth.id = Core.auth.uid = undefined;
	clearAuthorization( reason );
	if( elephCore ) {
		elephCore.auth = {
			uid: undefined,
			server: false
		}
	}
	window.socketLoginStr = null;
	fire( 'loggedin' );
}

Auth.fetch = async ( data, options ) => {
	let body = Object.assign( data, { uuid: window.UUID } ),
		action = options?.action || 'signin';
		// url = APIURL + '/' + action;
	if( LOCALTEST ) body.testmode = true;
	if( window.device ) {
		body.deviceuuid = device.uuid;
		body.platform = device.platform;
		if( device.isVirtual ) body.virtual = true;
	}
	if( window.cordova ) {
		body.packagename = cordova.packageName;
		body.version = cordova.versionNumber;
	}
	if( window.invitationsKnown )
		body.invitations = [...window.invitationsKnown];
	if( elephCore?.firstReferrer ) {
		if( !window.refererA ) {
			window.refererA = document.createElement( 'a' );
			refererA.href = elephCore.firstReferrer;
		}
		body['firstreferrer'] = encodeURI( refererA.origin + refererA.pathname );
	}
	body.lang = window.language;
	body.recentinteract = Date.now() - window.lastInteractTime;
	body.browser = getBrowserName();
	// return new Promise( async ( resolve ) => {
	log( '	Fetch auth: ' + action + ' ' + JSON.stringify( body ) );
	waitingFetch = true;
	let json = await API( action, body, action==='remind'? null : 'internal' );
	waitingFetch = false;

	if( !json ) {
		return { error: 'Network error' };
	}

	window.SIGNUP_IMPOSSIBLE = json.signup_impossible;

	if( json.error ) {
		log( `Fetch ${action}error: ${json.error}` );
		if( action==='signin' ) clearAuth( 'Signin error' );
		// Core.setAuth( {} );
		return json;
	}
	log( 'Fetched auth ' + JSON.stringify( json ) );

	if( json.long_token || json.token ) {
		let longtoken = json.long_token /*|| json.token*/;
		json.server = true;
		signed( json );
		fire( 'authjson', json );
		let auth = longtoken? json.uid + ':' + longtoken : undefined;
		json.authorization ||= auth;
		localStorage.loggedsometime = true;
		localStorage.lastsuccessauth = JSON.stringify( json );
		window.ME = json;
		window.UIN = json.uid || json.uin;
		window.myself = {
			_reason: 'SIGNIN',
			uin: json.uid,
			info: json.info
		};
		sessionStorage.authorization = window.AUTH = auth;

		if( json.head ) window.User?.update( json.head );
		longTries = 3;				// Снова 3 попытки на авторизацию по токену
		let useCookie = location.host.startsWith( 'www.' ) && json.cookie!==false;
		if( useCookie ) {
			let d = new Date();
			d.setMonth( d.getMonth() + 1 );
			let cookstr = `authorization=${encodeURIComponent( AUTH )}; secure; path=/; samesite=lax; expires=${d.toUTCString()}`;
			document.cookie = cookstr;
			// log( 'Cookie set: ' + cookstr );
			// log( 'Cookie check: ' + readCookie( 'authorization' ) );
		}
		if( window.STATIC ) {
			// Пока просто обновляем страницу после авторизации
			// toast( 'Please reload page' );
			location.reload( true );
			return;
		}

		if( !json.guest && json.type!=='uuid' ) {
			// Сохраним успешные авторизации из этого браузера
			let alluins = localStorage.authsuccesslist?.split( ' ' ) || [];
			localStorage.authsuccesslist = [...new Set( alluins ).add( UIN )].join( ' ' );
		}

		checkNeedSet();
		fire( 'loggedin' );
	}
	return json;
};


function checkNeedSet( showlogout ) {
	if( !elephCore ) return;
	let json = elephCore.auth,
		need = json['needselect'];
	// if( !need && LOCALTEST ) need = { password: 1 };
	// if( !need && LOCALTEST ) need = { nick: 1 };
	if( !need ) return;

	if( need.nick ) {
		setMyNick( showlogout );
		return true;
	}

	if( need.password ) {
		setMyPassword( showlogout );
		return true;
	}

	if( need.showname ) {
		setMyShowname( showlogout );
		return true;
	}
}

async function singleInputClick( e ) {
	let t = e.target,
		ct = e.currentTarget;
	if( !t ) return;
	if( t.dataset.name==='signout' ) {
		// Do signout and relogin
		ct.hide();
		doSignout();
		return;
	}
	if( t.dataset.name!=='ok' ) return;
	let input = ct.querySelector( 'input[required]' ),
		key = input.name;

	if( t.classList.contains( 'spinnable' ) )
		t.setSpinner( true );
	let res = await API( '/setmydata', {
		data: {
			[key]: input.value
		}
	}, 'internal' );
	if( res.ok ) {
		// Данные установлены
		User.update( {
			uid: res.uid,
			...res.info
		});
		ct.hide();
		if( res.uid===elephCore?.auth.uid ) {
			if( res['needselect'] )
				elephCore.auth.needselect = res['needselect'];
			else if( elephCore.auth.needselect )
				elephCore.auth.needselect[key] = false;
			if( res['info'] ) {
				elephCore.auth.info = res['info'];
				delay( 'checkmyself' );
			}
			updateAuth();

			let form = ct.querySelector( 'form[name="fake"]' );
			if( form ) {
				let fakeinput = form.querySelector( '[type="password"]' );
				fakeinput.value = input.value;
				// form.addEventListener( 'submit', e => e.preventDefault() )
				form.submit();
			}

		}
		checkNeedSet();
	} else {
		if( res.error ) toast( res.error );
		let button = ct.querySelector( '[data-name="ok"]' );
		button?.toggleDisabled( true );
	}
}

function singleInputChange( e ) {
	let ct = e.currentTarget,
		button = ct.$( '[data-name="ok"]' ),
		input = ct.querySelector( 'input' ),
		ok = input.validity.valid;
	// log( `Single input: value=${input.value}, valid=${ok}` );
	button.toggleDisabled( !ok );
}

function setMyPassword( showlogout ) {
	// Установить пароль на акк
	if( !boxMyPassword ) {
		boxMyPassword = makeBigWindow( {
			noads: true,
			title: '{Pleasecompleteregistration}',
			html:
				`<div class='vertical_form'>
			<span class='small'></span>
			<span class='mutable'>Set password</span>
			<div class="labelinput password">
<!--			<label title="{Password}">{Password}</label> -->
			<input type='text' class='login_inppassword markvalid' name='password' autocomplete='off' required minlength='6' 
			autocomplete='new-password' maxlength='30' placeholder='{newpassword}' title='Letters, digits, special symbols; minimum 6' 
			style='padding: 0.2em 0.5em'/>
			</div>
			<button class='control default spinnable' data-name='ok'>OK</button>
			<div class='display_none graybutton' data-name='signout'>{Signout}</div>
			</div>`
		} );
		// boxMyPassword.querySelector( '[name="newnick"]').pattern=`^[\\w]{4,}$`;
		boxMyPassword.querySelector( '[name="password"]' ).pattern = `^[\\S]{6,}$`;
		boxMyPassword.onclick = singleInputClick;
		boxMyPassword.oninput = singleInputChange;
	}
	boxMyPassword.querySelector( 'span.mutable' ).setContent(
		'{Setpassword} {for} ' + (elephCore.auth.id || UIN) );
	// boxMyPassword.querySelector( '[name="username"]' ).value = id;
	boxMyPassword.querySelector( "[data-name='signout']" ).classList.toggle( 'visible', !!showlogout );
	log( 'Show select password dialog' );
	boxMyPassword.setSpinner( false );
	boxMyPassword.show( true );
}

function setMyNick( showlogout ) {
	// Установить пароль на акк
	if( !boxMyNick ) {
		boxMyNick = makeBigWindow();
		boxMyNick.innerHTML = localize(
			`<div class='vertical_form'>
			<span class='small'>{Pleasecompleteregistration}</span>
			<span class='mutable'>{Selectnick}</span>
			<div class="labelinput">
<!--			<label title="{Nick}">{Nick}</label>-->
			<input required class='markvalid' name='nick' required minlength='4' maxlength='12' placeholder='{Yournick}' title='Letters, digits, and \"_\", minimum 4' />
			</div>
			<button class='control default' data-name='ok'>OK</button>
			<div class='display_none graybutton' data-name='signout'>{Signout}</div>
			</div>` );
		boxMyNick.querySelector( 'input' ).pattern = `^[а-яА-Я\\w]{4,}$`;
		boxMyNick.onclick = singleInputClick;
		boxMyNick.oninput = singleInputChange;
	}
	boxMyNick.$( 'span.mutable' ).setContent( `{Selectnick} (${window.ME?.id || UIN})` );
	boxMyNick.$( "[data-name='signout']" ).makeVisible( !!showlogout );
	log( 'Show select nick dialog' );
	boxMyNick.show( true );
}

function setMyShowname( showlogout ) {
	// Установить пароль на акк
	if( !boxMyShowname ) {
		boxMyShowname =
			makeBigWindow( {
				noads: true,
				id: 'selectshowname',
				html: `<div class='vertical_form'>
			<span class='mutable'>{Yourname}</span>
			<div class="labelinput">
			<input required class='markvalid' name='showname' required minlength='2' maxlength='32' placeholder='{Yourname}' />
			</div>
			<button class='control default spinnable' data-name='ok'>Ok</button>
			<div class='display_none graybutton' data-name='signout'>{Signout}</div>
			</div>`
			} );
		boxMyShowname.$( 'input' ).pattern = `^[^\'\"\`]{2,}$`;
		boxMyShowname.onclick = singleInputClick;
		boxMyShowname.oninput = singleInputChange;
	}
	boxMyShowname.$( "[data-name='signout']" ).makeVisible( !!showlogout );
	boxMyShowname.$( '.spinner' )?.setSpinner( false );
	log( 'Show select nick dialog' );
	boxMyShowname.show( true );
}

function doSignout() {
	if( elephCore?.auth.type==='uuid' ) {
		log( 'WARN: Trying to log out as guest. Keeping guest' );
		return;
	}
	if( elephCore ) {
		elephCore.do( 'type=logout' );
		elephCore.toserver( 'LOGOUT' );
	}
	fire( 'loggedout' );
	clearAuth( 'loggedout' );
	checkGuestAutoLogin();
}

export function signout() {
	return doSignout();
}

dispatch( 'signout', doSignout );
dispatch( 'signin', signin );

async function openChest() {
	let jres = await API( '/getmychest' );
	if( !jres?.ok ) return;
	(await import( './mods/tools.js' ) ).selectFrom( {
		items: jres.items,
		url: `/user/${UIN}/loot`
	} );
}

Auth.checkAuthBox = () => {
	if( Auth.box ) return;
	Auth.box = makeBigWindow( {
		noads: true,
	});
	let win = construct( '.column', Auth.box );
	Auth.saButtons = {
		me: construct( '.me.display_none', win ).html( `
				<div class='imagehead'>
					<img width='96' height='96' data-editable='always' data-magictype='avatar'>
					<div>
					  <div class='showname'></div>
					  <div class='lastseen'></div>
					</div>
				</div>` ),
		webInfo: construct( 'button.display_none.graybutton.control {Details}', win, getWebInfo ),
		keep: construct( '.display_none.graybutton.control', win ),
		signin: construct( 'button.graybutton.display_none.control {Signin}', win ),
		signout: construct( 'button.graybutton.display_none.control {Signout}', win, doSignout ),
		// mychest: construct( 'button.graybutton.display_none.control 👜 {Chest}', win, openChest ),
		options: construct( 'button.graybutton.control ⚙️ {Settings}', win, () => elephCore?.showSettings() )
	};
	Auth.saButtons.img = Auth.saButtons.me.$( 'img' );
	Auth.saButtons.showName = Auth.saButtons.me.querySelector( '.showname' );
	Auth.saButtons.lastSeen = Auth.saButtons.me.querySelector( '.lastseen' );

	Auth.box.onclick = () => {
		Auth.box.hide();
		sessionStorage['authoffered'] = Date.now();
	};
	Auth.box.onshow = () => {
		Auth.fillAvt();
	};

	Auth.fillAvt();
};

Auth.fillAvt = () => {
	let s = Auth.saButtons, auth = elephCore?.auth;
	if( !s || !auth ) return;
	let me = User.get( auth.uid );
	// s.webInfo.makeVisible( me?.name[0]!=='@' || false );
	if( auth['uid'] ) {
		s.img.setMagic( me?.getPicture || auth.info['avatarid'], 'user_' + auth.uid );
		s.showName.textContent = elephCore.auth.info && elephCore.auth.info.showname || '';
		s.lastSeen.setContent( elephCore.auth.type==='uuid' ? '{guest}' : '' );
	} else {
		s.img.srcset = `${svgIconsPath}/svg/icons/ic_person_outline_black_18px.svg`;
		s.showName.textContent = '';
		s.lastSeen.textContent = '';
	}
};

dispatch( 'loggedin', Auth.fillAvt );

export function signin() {
	return offerAuth( 'complete' );
}

export function clickMyself() {
	// Может быть мы не завершили регистрацию?
	let res = checkSignupProcedure();
	if( res ) return;

	if( elephCore.auth?.server ) {
		if( checkNeedSet( true ) ) {
			return;
		}
		// if( GAMBLERRU && !LOCALTEST )
		// 	offerAuth( 'info' );
		// else
			import( './mods/team.js' ).then( mod => {
				mod.teamMember();
			});
	} else
		offerAuth( 'complete' );
		// Core.login ? Core.login() : delay( 'login' );
}

function getWebInfo() {
	localBrowser( LOCALTEST ? '/tours?state=1' : '/user/' + UIN );
}

document.addEventListener( 'keydown', function( e ) {
	// Ctrl+A - login
/*
	if( (e.key==='a' && e.ctrlKey) ) {
		offerAuth();
		e.stopPropagation();
		return false;
	}
*/
} );

function offerAuth( param ) {
	// Offer to authorize or complete guest login (enter guestname) or register
	dropWaitAuth();
	if( param==='check' ) {
		if( elephCore?.auth.uid && User.getNick( elephCore.auth.uid ) ) {
			if( elephCore.auth.type!=='uuid' || sessionStorage['authoffered'] ) {
				// resolve();
				return Promise.resolve();
			}
		}
	}

	if( checkNeedSet() ) {
		return Promise.reject();
	}

	// Возможно, мы не завершили процесс регистрации. В этом случае надо завершать
	if( param==='complete' ) {
		let auth = elephCore?.auth;
		// Если выполняется запрос к серверу на проверку авторизации, просто ждем
		if( waitingFetch )
			return forceWaitAuth();
		if( auth?.uid && !auth.guest && auth.type!=='uuid' && auth.info.showname ) {
			if( !auth.server ) {
				// Еще не подтвердился от сервера. Судя по всему, начало работы
				return forceWaitAuth();
			}
			// Полноценный пользователь
			return Promise.resolve();
		}
		if( UIN && AUTH ) return Promise.resolve();
		return new Promise( resolve => {
			longTries = 0;
			loginForm.show( resolve );
		} );
	}
	Auth.checkAuthBox();
	let gname = User.getNick( UIN ),
		info = param==='info',
		auth = elephCore?.auth,
		guest = auth?.type==='uuid';
	if( gname ) gname += ' ({guest})'; else gname = '{guest}';
	Auth.saButtons.me.makeVisible( info );
	Auth.saButtons.webInfo.makeVisible( (info && UIN && !guest || false) );
	Auth.saButtons.keep.setContent( `{Continueas} ${gname}` );
	Auth.saButtons.keep.makeVisible( !info /*&& window.device && window.device.uuid || false*/ );
	Auth.saButtons.signin.makeVisible( !UIN || guest || !elephCore?.auth.server );
	Auth.saButtons.signout.makeVisible(  UIN && auth && !guest || false );
	Auth.saButtons.mychest?.makeVisible(  UIN && !guest || false );
	hideErrMessage();
	Auth.box.show();
	log( 'win: offer auth, ios=' + window.IOS );

	return new Promise( ( resolve, reject ) => {
		Auth.saButtons.keep.onclick = () => {
			if( User.getNick( UIN ) )
				resolve();
			else
				offerShowName( resolve );
		};
		Auth.saButtons.signin.onclick = () => {
			loginForm.show( resolve );
		}
	} );
}

Auth.checkAuth = type => {
	// returns true if good authorization for playing
	return offerAuth( type || 'complete' );
};

Auth.checkAuthGuest = async options => {
	// Make at least guest login
	if( UIN ) return;
	// Пока авторизуемся гостем, не проверяя были ли авторизации раньше
	// Хочет гостем - пусть играет гостем, не заморачиваем
	// TODO: здесь хорошо бы предложить окно "продолжить гостем"
	// TODO: или считать что есть кем зайти, если не пуст список выбора (лучше всего)
	// if( localStorage.authsuccesslist )
	// 	return checkAuth();

	let json = await Auth.fetch( {
		type: 'uuid',
		create: true,
		...options
	} );
	// Если удалось авторизоваться гостем, отлично
	if( UIN ) return;
	// Иначе обычная процедура
	return checkAuth();
};

dispatch( 'checkauth', Auth.checkAuth );

function offerShowName( resolve ) {
	let box = offerShowName.box;
	if( !box ) {
		box = offerShowName.box = makeBigWindow(
			// let box = construct( '#login.fade.bigwindow' );
			`
    		    <div><form class="vertical_form showname_form">
    		    <span>{Enteryourname}</span>
		        <input class='login_inpname' name='id' type='text' autocomplete="username" required autofocus minlength='3' maxlength='50' placeholder="" />
		        <button class='control'>Ok</button>
		        <span class='small'>{Enteryourname_cmt}</span>
       			</form></div>
        	` );

		let form = box.querySelector( 'form' );
		box.onsubmit = async e => {
			e.preventDefault();
			let input = box.querySelector( 'input' ),
				name = input.value;
			if( name?.length>=2 && (!elephCore?.auth.info || name!==elephCore.auth.info.showname) ) {
				// Для задания showname используем запрос логина повторно
				let response = await Auth.fetch( {
					type: 'uuid',
					showname: name
				} );
				// Successfully received code
				log( 'Fetch succeded' );
				resolve?.();
				box.hide();
			}
		}
	}
	box.show();
}

function checkStartAction() {
	let action = coreParams.startAction;
	coreParams.startAction = null;
	if( UIN ) return; 		// Авторизован
	if( action==='register' ) {
		loginForm.goregister();
	}
}

async function checkGuestAutoLogin() {
	if( elephCore?.params.autologin ) return false;
	let json = await Auth.fetch( {
		type: 'uuid',
		create: false
	} );
	checkStartAction();
	if( json.error ) {
		// Dont do login form, because this is only first start
		// just be a free walker now
		return;
	}
	log( 'auth: server succes by UUID' );
	// Auth.setLoginStr( 'UUID ' + window.UUID + '\nLOGIN uuid=' + window.UUID );
	return true;
}

let firstauthdone;
Auth.firstAuth = () => {
	subscribeMe();

	if( window.cordova && !window.DEVICEREADY ) return log( 'First auth. Device still not ready' );
	if( !elephCore || !modules.Subscribe || firstauthdone ) {
		log( `First auth. elephCore=${!!elephCore} modules.Subscribe=${!!modules.Subscribe} firstauthdone=${firstauthdone}` );
		return;
	}
	firstauthdone = true;
	let confirmkey = GETparams.get( 'confirmkey' );
	if( confirmkey ) {
		log( 'Trying to auth with confirmkey ' + confirmkey );
		Auth.fetch( {
			confirmkey: confirmkey
		} );
		return;
	}
	let auth = readCookie( 'authorization' );
	log( 'auth=' + auth + ' all cookie=' + document.cookie );
	if( auth==='unlog' ) {
		// Просьба разлогиниться
		clearAuthorization( 'Unlog cookie' );
		checkGuestAutoLogin();
		return;
	}
	if( auth ) {
		// Авторизуемся единой кукой
		Auth.fetch( {
			cookauthorization: auth
		} );
		return;
	}

	if( !elephCore.params['nostoretoken'] && elephCore.params['autologin']!==false ) {
		if( elephCore.params['token'] ) {
			// Try to authorize with token
			window.setLoginStr( 'LOGIN -tag=fixedtoken uuid=' + window.UUID + ' token=' + elephCore.params['token'] );
			elephCore.auth = {
				token: elephCore.params['token'],
				info: { showname: elephCore.params['showname'], fants: elephCore.params['fants'] }
			};
		} else {
			// if( token ) token = btoa( token );
			let me = localStorage.lastsuccessauth;
			if( me?.needselect ) me = null;
			log( 'Last success auth is ' + me );
			let o = me && me[0]==='{' && !me.includes( 'error' ) && JSON.parse( me );
			if( o?.long_token ) {
				o.server = false;
				signed( o );
				// elephCore.setAuth( o );
				elephCore.setAuth( o );
				window.UIN = o.uid;
				sessionStorage.authorization = window.AUTH = o.uid + ':' + o.long_token;
				if( o.uid ) stored[o.uid] = o;
				if( !o.id ) o.id = o.uid;
				if( o.token ) {
					// Try to login by our token
					window.setLoginStr( `LOGIN -tag=storedtoken uuid=${window.UUID} token=${o.token}` );
				}
				fire( 'onesignal_externaluserid', o.onesignal_externaluserid );
				// Если OneSignal еще не загрузился, оставим для него информацию
				window.ONESIGNAL_EXTERNALUSERID = o.onesignal_externaluserid;
			}
		}
		// If we have to login then dont login by UUID
		if( !window.socketLoginStr ) checkGuestAutoLogin();
	}
};

Auth.firstAuth();

window.maskID = maskID;

function maskID( id ) {
	if( id.includes( '@' ) ) {
		let m = id.match( /^(\w)(.*)@(\w)(.*)(\w\..*)$/ );
		return ( m[1] + '.'.repeat( m[2].length ) + '@' + m[3] + '.'.repeat( m[4].length ) + m[5] ) || id;
	}
	return id;
}

function storeAllSigned() {
	localStorage.allsigned = JSON.stringify( allSigned );
}

function signed( o ) {
	if( o.type==='uuid' ) return;
	allSigned[o.uid] = {
		subname: maskID( o.id ),
		picture: o.info.picture,
		showname: o.info.showname,
		lastlogin: Date.now()
	};
	storeAllSigned();
}

let
	onresolve,
	sentrequest = false, enableAgainId,
	waitingCheck,
	apiCheckCounter = 0, apiCheckNeed, apiCheckWaiting, apiPhaseCount = 0,
	periodicCheckId, periodicCheckCount = 0,
	signupOK,
	mode, signupPhase = 'selectid',
	inpform, inpname,
	errmessage, hideErrMessageId,
	phaseBlock, phaseBlocks = {};

class LoginForm {
	#loginKeyBind;

	constructor() {

	}

	checkBox() {
		if( this.box ) return;
		this.box = makeBigWindow( {
			noads: true,
			title: '{Authorization}',
			html: `
		<div class='vertical_form authform bigfont' style='position: relative'>
		<button class='likeabutton' style='position: absolute; right: 0; bottom: 0; border: none; margin: 0; background: transparent' data-execute='bugreport.'>🐞</button>
		<span class='display_none title'>{Login}</span>
        <div class="vertical_form login_form display_none signin" style='position: relative'>
			<input type='hidden' name='hiddenid' />
			<div class='formplace_passwordforselected'>
			<form>
			<div class='display_none visible block_inputname' style='margin:0.3em 0.5em'>
			<input class='login_inpname' tabindex='1' name='userid' type='text' autocomplete="username" maxlength='50' placeholder='Email' />
			</div>
			<div class='display_none block_selectedaccount' style='align-self: start'>
			</div>
			<div class='password' style='margin: 1em 0.5em 1.5em 0.5em'>
			  <input default id='signin_psw' class='login_inppassword' tabindex='2' data-tab='1' type='password' name='psw' autocomplete="current-password" required minlength='3' maxlength='30' placeholder='{password}' title='Letters, digits, and \"_\", minimum 3' data-mytouch='1'/>
			  <button style='color: var( --light_black )' tabindex='-1'>👁</button>
			</div>
  		    </form>
  		    </div>
        	<a class='display_none' data-name='forgot' href='' 
        		style='text-decoration: none; color: var(--color_subtitle); font-size: 1rem; position: absolute; margin: 0; bottom: -10px; right: 20px'>{forgotpassword}</a>
        </div>
        <div class="vertical_form login_form display_none labelinput remind">
			<input id='auth_remind_email' class='login_inpname' tabindex='1' name='email' type='email' inputmode="email" 
				autocomplete="email" maxlength='50' placeholder=' ' style='margin-bottom: 1em' 	/>
			<label class='flying' title="Email" for='auth_remind_email'>Email</label>
        </div>
        <div class="vertical_form login_form display_none signup">
		<div class="display_none labelinput" data-phaseblock="selectid" style='margin-bottom: 1em'>
	        <input class='login_inpname markvalid' size="30" data-tab="10" tabindex="10" name='newemail' type='email' autocomplete="email" inputmode="email" required minlength='3'  maxlength='50' placeholder=' ' />
			<label class='flying' title="Email">Email</label>
		</div>
		<div class="display_none code labelinput" data-phaseblock="confirmcode">
	        <input class='login_inpcode' data-tab="12" tabindex="12" size='4' name='confirmcode' required minlength='4' maxlength='4' inputmode="numeric" />
			<label title="{Code}">{Code}</label>
		</div>
        </div>
        <div class='supportarea'>
			<div class='flexline buttonline spacearound' style='margin: 0; width: 100%'>
				<span class='display_none clickable' 
				style='text-decoration: none; color: var(--light_black); font-size: 1rem; margin: 0 0.5em; padding: 0.2em' 
				data-name='back' href=''>{Back}</span>
				<button class='default fade visible control' style='margin: 0.5em; padding: 0.2em 0.5em' data-name='ok'>OK</button>
			</div>
			<span class='fade errormessage'></span>
        </div>
        <span class='display_none control' href='' data-name='change' 
        	style='color: var( --color_subtitle ); margin-top: 0.5em; font-size: 1rem'>{Registration}</div>
        <div class='display_none graybutton' data-name='apple'>{Signin} {with} AppleID</div>
        </div>`
		} );

		this.box.querySelector( '[name="userid"]').pattern=`^[а-яА-ЯёЁ\\w\\-\\.@]*$`;
		this.box.querySelector( '[name="psw"]').pattern=`^[\\S]{3,}$`; // ^(?=.*\d)(?=.*[a-zA-Z])(?!.*\s).*$
		this.buttonOk = this.box.querySelector( '[data-name=ok]' );
		this.box.onclick = click;

		this.form = this.box.firstElementChild;

		this.backButton = this.box.$( '[data-name=back]' );
		this.forgotButton = this.box.$( '[data-name=forgot]' );
		this.changeButton = this.box.$( '[data-name=change]' );
		this.blockSelectedaccount = this.box.$( '.block_selectedaccount' );
		this.blockInputname = this.box.$( '.block_inputname' );
		this.inphidden = this.box.$( 'input[type="hidden"][name="hiddenid"]' );
		this.appearanceCounter = 0;
			// inphiddenname = box.$( 'input[data-hiddenname]' ),
// signinApple = box.$( '[data-name=apple]' ),

		this.backButton.onclick = goback;
		this.forgotButton.onclick = this.forgotClick.bind( this );

		if( window.cordova ) {
			let change = this.form.$( '[data-name="change"]' );
			change.toggleDisabled( false );
		}

		let ar = this.form.querySelectorAll( '[data-phaseblock]' );
		for( let o of ar ) {
			let k = o.dataset['phaseblock'];
			phaseBlocks[k] = {
				holder: o,
				label: o.querySelector( 'label' ),
				input: o.querySelector( 'input' )
			};
		}

		this.box.onShow = () => {
			log( 'Login show started ' + this.appearanceCounter );
			this.needEdit = false;
			this.requestDone();			// Пока что при показе всегда сбросим запрос
			// это может привести к коллизиям, зато не будет блокировок входа
			this.checkMode();
			this.checkEnabledOk();
			this.#loginKeyBind = this.loginkey.bind( this );
			document.addEventListener( 'keydown', this.#loginKeyBind );
			this.appearanceCounter++;
		};

		this.box.onHide = () => {
			document.removeEventListener( 'keydown', this.#loginKeyBind );
			log( 'Login window hided' );
			storeState();
		};

		this.box.addEventListener( 'input', modified );

		this.checkMode( startMode );

		if( s_json?.id )
			inpname.value = s_json.id;
		else {
			let ll = localStorage['lastlogin'];
			if( ll ) inpname.value = ll;
		}
	}

	forgotClick( e ) {
		if( !signingIn() ) return;
		e.preventDefault();
		e.stopPropagation();
		// Скопируем id из формы авторизации
		let loginid = this.box.querySelector( `.signin .login_inpname` ),
			remindid = this.box.querySelector( `.remind .login_inpname` );
		let val = loginid.value;
		if( val.includes( '@' ) ) remindid.value = val;

		this.checkMode( 'remind' );
	}

	async homepage() {
		this.checkBox();
		let showinputname = true;
		this.inphidden.value = '';
		if( !mode || isremind() ) this.checkMode( 'signin' );
		else {
			// Можно попробовать дать выбрать сради ников, которые здесь логинились
			if( Object.keys(allSigned).length ) {
				// Открываем окно со всеми логинами
				this.box.hide();
				let win = makeBigWindow( {
					noads: true,
					repeatid: 'signselect',
					title: '{Authorization}',
					html: "<div class='column' style='padding: 0.5em; gap:0.5em'>"
						+ Object.entries( allSigned ).reduce( ( prev, current ) => {
							return prev + fillPlayerHTML( current[0], {
								control: true,
								dataset: {
									closeselect: current[0]
								}
							} )
						}, '' ) +
						`<span class='grayhover' style='border-top: 0.5px solid gray; padding: 0.5em;' data-closeselect="another">{Anotheraccount}</span>
						<span class='display_none visible grayhover iamnewuser' style='padding: 0.5em; color: var( --color_subtitle )' data-closeselect="signup">{Iamnewuser}</span>
						</div>`
				});
				win.$( '.iamnewuser' ).makeVisible( !window.SIGNUP_IMPOSSIBLE );
				let uid = await win.promiseShow();
				log( 'Login with ' + uid );
				if( uid==='close' || uid==='cancel' ) return;
				if( +uid ) {
					// TODO: авторизация с уже выбранным ID пользователя
					// toast( 'To be shot (DEBUG MODE)' );
					showinputname = false;
					this.blockSelectedaccount.html( fillPlayerHTML( uid ) );
					this.checkMode( 'signin' );
					let user = User.get( uid ),
						userid = user?.getShowName || allSigned[uid]?.name || uid;
					/*inphiddenname.value =*/ inpname.value = userid;
					// inpname.dataset.value = userid;
					this.inphidden.value = uid;
					// Попробуем полностью заменить HTML
					this.box.$( '.formplace_passwordforselected' ).html( `
					<form>
					<div class='display_none block_inputname' style='margin:0.3em 0.5em'>
					<input class='login_inpname' name='userid' type='text' autocomplete="username" maxlength='50' placeholder='Email' value='${userid}' />
					</div>
					<div class='block_selectedaccount' style='align-self: start'>
					${fillPlayerHTML( uid )}
					</div>
					<div class='password' style='margin: 1em 0.5em 1.5em 0.5em'>
					  <input default id='signin_psw' class='login_inppassword' tabindex='2' data-tab='1' type='password' name='psw' autocomplete="current-password" required minlength='3' maxlength='30' placeholder='{password}' title='Letters, digits, and \"_\", minimum 3' />
					  <button style='color: var( --light_black )' tabindex='-1'>👁</button>
					</div>
					</form>` );
				} else {
					if( uid==='signup' ) this.checkMode( 'signup' );
					else {
						inpname.value = '';
						this.checkMode( 'signin' );
						this.box.$( '.formplace_passwordforselected' ).html( `
							<form>
							<div class='block_inputname' style='margin:0.3em 0.5em'>
							<input class='login_inpname' name='userid' tabindex='1' type='text' autocomplete="off" maxlength='50' placeholder='Email' value='' />
							</div>
							<div class='password' style='margin: 1em 0.5em 1.5em 0.5em'>
							  <input id='signin_psw' class='login_inppassword' tabindex='2' data-tab='1' type='password' name='psw' autocomplete="off" required minlength='3' maxlength='30' placeholder='{password}' title='Letters, digits, and \"_\", minimum 3' />
							  <button style='color: var( --light_black )' tabindex='-1'>👁</button>
							</div>
							</form>` );
					}
				}
			}
		}
		this.blockSelectedaccount.makeVisible( !showinputname );
		this.blockInputname.makeVisible( showinputname );
		// Если никогда не логинился, предложим создать ник
		checkSignupPhase();
		await cssInject( 'login' );
		this.box.show()
	}

	show( resolve ) {
		onresolve = resolve;
		this.homepage();
	}

	async goregister() {
		this.checkBox();
		log( 'Go register' );
		if( window.checkStandings )
			await window.checkStandings();
		this.checkMode( 'signup' );
		await cssInject( 'login' );
		this.box.show();
	}

	fillJSON() {
		let json = {
			id: inpname.value.trim()
		};
		if( signingUp() ) {
			json.phase = signupPhase;
			// if( signupPhase==='selectnick' ) json.nick = inpnick.value;
			if( signupPhase==='confirmcode' ) json.code = phaseBlock.input.value;
		} else if( signingIn() ) {
			json.password = this.box.$( '.login_inppassword' ).value.trim();
			if( this.inphidden.value ) json.id = this.inphidden.value;
		}
		return json;
	}

	checkEnabledOk() {
		let e = !this.needEdit && isvalid();
		// if( e && mode==='signup' ) e = signupOk;
		this.buttonOk.toggleDisabled( !e );
		this.buttonOk.makeVisible( e || !errmessage?.isVisible() );
		if( e ) hideErrMessage( true );
		this.backButton.makeVisible( signingUp() && signupPhase==='confirmcode' || isremind() );
		this.forgotButton.makeVisible( signingIn() );
		this.changeButton?.makeVisible( signupPhase!=='confirmcode' && !isremind() && !window.SIGNUP_IMPOSSIBLE );
	}

	checkMode( newmode ) {
		if( newmode ) {
			if( newmode===mode ) return;
			if( newmode==='signup' ) {
				// Проверим можно ли регистрироваться
				if( window.SIGNUP_IMPOSSIBLE ) {
					if( window.EXTERNALDOMAIN /*&& !window.domainData?.canregister*/ ) {
						// Need to receive invitation from manager
						bigInfo( {
							picture: window.domainData?.team?.getPicture || 'professor',
							text: '{Biginfo_needaninvitationcodetoregister}'
						} );
						return;
					}
				}
			}
			mode = newmode;
		}
		if( mode==='signup' ) {
			// Определим стадию signup
			checkSignupPhase();
		} else
			this.buttonOk.setContent( isremind()? "Ok" : "{Login}" );
		this.form.dataset['signmode'] = mode;
		log( `Sign mode is ${mode}` );
		this.changeButton?.setContent( mode==='signin'? '{Iamnewuser}' : '{Ihaveanaccount}' );
		this.changeButton?.makeVisible( ( signingIn() || signingUp() ) && !window.SIGNUP_IMPOSSIBLE );
		let titles = {
			signin: '{Authorization}',
			signup: '{Signup}',
			remind: '{Passwordreminder}'
		};
		let title = this.box.$( 'span.title' );
		title.setContent( titles[mode] );
		title.makeVisible( mode==='remind' );

		// inpnick = box.querySelector( `.${mode} .login_inpnick` );
		inpform = this.box.querySelector( `.${mode}` );
		inpname = this.box.querySelector( `.${mode} .login_inpname` );
		errmessage = this.box.querySelector( '.errormessage' );
		// inppsw.mytouch = inpname.mytouch = true;
		// inpname.onfocus = inppsw.onfocus = onfocus;
		inpname.focus();
		this.checkEnabledOk();
		hideErrMessage( true );
		storeState();

		for( let o of this.form.querySelectorAll( '.login_form' ) ) {
			o.makeVisible( o.classList.contains( mode ) );
		}
	}

	loginkey( e ) {
		// Мутная функция обработки событий при вводе пароля,
		// и не менее мутная проверка оттуда ли пришла клавиша
		let t=e.target;
		for( ; t; t=t.parentElement ) if( t===this.box ) break;
		if( !t ) return;
		if( e.key==='Tab' ) {
			let tab = e.target.dataset['tab'], tabi = e.target.tabIndex,
				newtab = tab || (tabi+1);
			if( newtab ) {
				let e = this.box.querySelector( `[tabindex='${newtab}']` );
				if( e ) e.focus();
			}
			e.stopPropagation();
			e.preventDefault();
			return false;
		}

		if( e.key===' ' ) {
			e.preventDefault();
			return false;
		}

		if( e.key==='Enter' ) {
			// Перехватим ENTER самостоятельно (для смартов)
			e.stopPropagation();
			e.preventDefault();
			if( mode==='signin' ) {
				if( !inpname.value.length ) return inpname.focus();
				if( !this.box.$( '.login_inppassword' ).value.length ) return this.box.$( '.login_inppassword' ).focus();
			}
			send();
			return false;
		}
	}

	requestDone() {
		if( !sentrequest ) return;
		sentrequest = 0;
		this.buttonOk.classList.remove( 'sentrequest' );
		enableAgainId = 0;
		this.checkEnabledOk();
	}

	signedIn() {
		log( 'Signed IN. done' );
		this.box.$( `.signin .login_inpname` ).value = '';
		this.box.$( `.signin .login_inppassword` ).value = '';
		inpname.value = '';
		signupPhase = 'selectid';
		this.box.hide();
		clearPeriodiccheck();
		if( onresolve ) {
			let temp = onresolve;
			onresolve = null;
			temp();
		}
	}

	signedUp( result ) {
		log( 'Signed UP. done' );
		let name = inpname.value || ( result && result.id ) || '',
			box = this.box;
		box.$( `.signin .login_inpname` ).value = name;
		box.$( `.signin .login_inppassword` ).value = '';
		inpname.value = '';
		signupPhase = 'selectid';
		this.checkMode( 'signin' );
		box.hide();
		checkSignupPhase();
		clearPeriodiccheck();
	}
}

export let loginForm = new LoginForm();

// "|^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,6})$|i"
// <input id='username' type='username' name='name' autoComplete="username" data-hiddenname='1' style='display:none'/>

// inppsw = box.querySelector( `.login_inppassword` );

// let eyebtn = box.querySelector( '.password>button' );

// Переключатель типа ввода пароля
// Отказ от фокуса
// eyebtn.onfocus = e => { inppsw.focus(); e.preventDefault(); return false; };

let startMode = 'signin',
	startId,
	s = localStorage.signingUP,
	s_json = s && JSON.parse( s );

if( s_json ) {
	startMode = s_json.mode;
	signupPhase = s_json.phase,
		startId = s_json.id;
	apiPhaseCount = 0;
	if( !['signin','signup'].includes( startMode ) ) {
		startMode = 'signin';
	}
}

function signingUp() { return mode==='signup'; }
function signingIn() { return mode==='signin'; }
function isremind() { return mode==='remind'; }

function goback( e ) {
	e.stopPropagation();
	e.preventDefault();
	if( isremind() ) return loginForm.checkMode( 'signin' );
	if( !signingUp() || signupPhase!=='confirmcode' ) return;
	signupPhase = 'selectid';
	apiPhaseCount = 0;
	checkSignupPhase();
	loginForm.checkEnabledOk();
}

function isvalid( fix ) {
	if( sentrequest ) return false;
	if( mode==='signup' ) {
		return phaseBlock.input.validity.valid;
	}
	if( loginForm.blockInputname.isVisible() ) {
		// Если выбираем и id и пароль, то проверим id
		if( !inpname.value.length || !inpname.validity.valid ) return false;
	}
	if( signingIn() && !loginForm.box.$( '.login_inppassword' ).validity.valid ) return false;
	return true;
}

function checkSignupPhase() {
	if( !signingUp() ) return;
	if( !['selectid','confirmcode'].includes( signupPhase ) ) signupPhase = 'selectid';
	loginForm.form.dataset.signupphase = signupPhase;
	const phrase = {
		selectid: '{Register}'
	};
	loginForm.buttonOk.setContent( phrase[signupPhase] || "{Nextstep}" );
	let ar = loginForm.form.querySelectorAll( '[data-phaseblock]' );
	for( let o of ar ) {
		o.classList.toggle( 'visible', o.dataset['phaseblock']===signupPhase );
	}
	phaseBlock = phaseBlocks[signupPhase];
	phaseBlock.input.focus();
	if( signupPhase==='confirmcode' ) {
		let label = phaseBlock.label;
		label.setContent( '{Confirmcodesentto} ' + ( inpname?.value || startId ) );
		loginForm.buttonOk.makeVisible( phaseBlock.input.validity.valid );
	}
}

function checkErrors( errors ) {
	if( !errors ) errors = {};
	checkField( 'email', errors['id'] );
	// checkField( 'nick', errors['nick'] );
	// checkField( 'password', errors['password'] );
}

function click( e ) {
	if( !e.target ) return;
	e.preventDefault();
	// e.preventDefault();
	// e.stopPropagation();

	if( e.target.dataset['name']==='change' ) {
		loginForm.checkMode( mode==='signin' ? 'signup' : 'signin' );
		e.stopPropagation();
		return;
	}
	if( e.target.dataset.name==='ok' ) {
		e.stopPropagation();
		send();
	}
}

async function send( silent ) {
	if( !isvalid( true ) ) return false;

	sentrequest = Date.now();
	loginForm.form.classList.add( 'sentrequest' );
	loginForm.buttonOk.hide();
	// if( mode==='signup' )
	// 	enableAgainId = setTimeout( 3000, requestDone );
	if( !silent && window.SoftKeyboard ) SoftKeyboard.hide();

	let json = loginForm.fillJSON();
	apiPhaseCount++;

	let result = await Auth.fetch( json, { action: mode } );

	// if( result?.ok && isremind() ) toast( '{Messagesent}. {Pleasefollowtheinstructions}' );

	if( enableAgainId ) clearTimeout( enableAgainId );

	if( silent ) {
		// В тихом режиме отрабатываем только ОК
		if( result?.ok ) nextPhase( result );
		loginForm.requestDone();
		return;
	}

	if( result ) {
		checkErrors( result.errors );
		if( result.error ) {
			showError( result.error );
			loginForm.needEdit = true;
		} else {
			if( result.errors ) {
				loginForm.needEdit = true;
			} else {
				nextPhase( result );
			}
		}
	}
	loginForm.requestDone();
}

function hideErrMessage( full ) {
	if( hideErrMessageId ) clearTimeout( hideErrMessageId );
	hideErrMessageId = null;
	errmessage?.hide();
	loginForm.buttonOk?.show();
	if( full && inpform )
		for( let o of inpform.querySelectorAll( 'input' ) )
			o.classList.remove( 'warnoutline' );
}

function showError( err ) {
	toast( err );
	if( errmessage ) {
		errmessage.setContent( err );
		errmessage.show();
		loginForm.buttonOk?.hide();
		hideErrMessageId = setTimeout( hideErrMessage, 10_000 );
	}
	if( inpform )
		for( let o of inpform.querySelectorAll( 'input' ) )
			o.classList.add( 'warnoutline' );
}

function nextPhase( result ) {
	if( signingIn() ) return loginForm.signedIn();
	if( isremind() ) return loginForm.checkMode( 'signin' );
	if( result?.newphase ) {
		if( result.newphase==='registered' ) {
			// Регистрация завершена
			return loginForm.signedUp();
		}
		signupPhase = result.newphase;
		apiPhaseCount = 0;
		return checkSignupPhase();
	}
	if( signupPhase!=='selectid' ) {
		// Была стадия confirmcode
		loginForm.signedUp();
		return;
	}
	signupPhase = 'confirmcode';
	box.$( `.login_inpcode` ).value = '';
	apiPhaseCount = 0;
	if( !signupPhase ) {
		// Signup completed
		return box.hide();
	}
	clearPeriodiccheck();
	if( signupPhase==='confirmcode' ) {
		periodicCheckId = setTimeout( periodicCheck, 10000 );
		periodicCheckCount = 0;
	}
	checkSignupPhase();
	storeState();
}

function clearPeriodiccheck() {
	if( periodicCheckId ) clearTimeout( periodicCheckId );
	periodicCheckId = null;
}

async function periodicCheck() {
	periodicCheckId = null;
	if( !signingUp() || signupPhase!=='confirmcode' ) return;
	log( 'Trying to autocheck code... ' + periodicCheckCount );
	periodicCheckCount++;
	// ASYNC operation
	let res = await Auth.fetch(  {
		id: inpname.value,
		phase: 'confirmcode',
		periodiccheck: true
	}, {
		action: 'signup'
	} );
	if( res.ok && res.long_token ) {
		loginForm.signedUp();
		return;
	}

	// if( periodicCheckCount > 5 ) return;
	periodicCheckId = setTimeout( periodicCheck, 10000 );
}

async function apiCheck() {
	if( signupPhase==='confirmcode' ) {
		if( !apiPhaseCount ) send( true );
		return;
	}
	if( apiCheckWaiting ) {
		apiCheckNeed = true;
		return;
	}
	apiCheckNeed = false;
	let json = loginForm.fillJSON();

	if( Object.keys(json).length==0 ) {
		// Нечего проверять
		return;
	}

	json.checkonly = true;

	apiPhaseCount++;
	apiCheckCounter++;
	log( `signup check ${apiCheckCounter}: ${JSON.stringify( json )}` );
	apiCheckWaiting = true;
	let result = await Auth.fetch( json, { action: 'signup' } );
	apiCheckWaiting = false;
	log( `signup result ${apiCheckCounter}: ${JSON.stringify( result )}` );

	if( waitingCheck ) return;		// Ждем изменений
	if( apiCheckNeed ) return apiCheck();

	signupOK = result?.ok;
	if( signupOK ) loginForm.checkEnabledOk();
	checkErrors( result.errors );
}

function checkField( field, error ) {
	let input = loginForm.box.querySelector( `[name='new${field}']` ),
		label = input.nextElementSibling,
		txt = error || ''; // label.title;
	label.setContent( txt );
}

function getPhaseName() {
	if( signingIn() ) return "SigningIN";
	return "SigningUP/" + signupPhase;
}

function modified( e ) {
	signupOK = false;
	hideErrMessage( true );
	let t = e.target,
		srlog = sentrequest? ' requested ' + (Date.now()-sentrequest) + 'ms' : '';
	log( `login mdf: ${getPhaseName()}. ${t.name}=<${t.value}> ${srlog}. apc=${apiPhaseCount}, mode=${mode}, phase=${signupPhase}` );
	// Strip spaces
	let trimmed = t.value.trim();
	if( trimmed!==t.value ) {
		log( 'Trimmed: ' + trimmed );
		t.value = trimmed;
	}
	if( !t.value && t.classList.contains( 'login_inpname' ) ) {
		// Clear password
		let elpsw = t.closest( 'form' )?.$( '.login_inppassword' );
		if( elpsw ) elpsw.value = '';
	}
	if( loginForm.needEdit ) checkErrors();
	storeState();
	loginForm.needEdit = false;
	if( waitingCheck ) {
		clearTimeout( waitingCheck );
		waitingCheck = setTimeout( recheckAfterTimeout, 700 );
		return;
	}
	if( !needApiCheck() )
		rechecknow();
	else {
		loginForm.buttonOk.toggleDisabled( true );
		if( signupPhase==='confirmcode' ) {
			// Первая проверка мгновенно
			log( 'Immediatly check first code accept, apicheckcount=' + apiPhaseCount )
			rechecknow();
		} else {
			waitingCheck = setTimeout( recheckAfterTimeout, 700 );
		}
	}
	if( signingUp() && signupPhase==='selectid' )
		storeState();
}

function recheckAfterTimeout() {
	waitingCheck = 0;
	rechecknow();
}

function needApiCheck() {
	if( !signingUp() ) return false;
	if( signupPhase==='selectnick' ) return true;
	if( signupPhase==='confirmcode' && !apiPhaseCount && phaseBlock.input.validity.valid ) return true; // первая проверка
	return false;
}

function rechecknow() {
	if( waitingCheck ) clearTimeout( waitingCheck );
	waitingCheck = 0;
	if( !needApiCheck() ) {
		loginForm.buttonOk.toggleDisabled( !isvalid() );
	} else {
		apiCheck();
	}
}

function checkSignupProcedure() {
	if( loginForm.box?.isVisible() ) return true;		// Мы в процессе авторизации
	if( loginForm.box && signingUp() && window.isGuest() ) {
		log( 'Signing up. Show auth window. AUTH=' + JSON.stringify( AUTH ) );
		(async function() {
			await cssInject( 'login' );
			loginForm.box.show();
		})();
		return true;
	}
	return false;
}

function storeState() {
	if( !loginForm.box.isVisible() || !signingUp() || ( signupPhase==='selectid' && !inpname.value ) ) {
		delete localStorage.signingUP;
		return;
	}
	localStorage.signingUP = JSON.stringify( {
		mode: mode,
		phase: signupPhase,
		id: inpname.value
	});
}

// Do first subscriptions
subscribeMe();


